<template>
    <b-card>
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent="submitForm">
                <b-row>
                <b-col md="4">
                    <b-form-group>
                        <label>Report Type</label>
                        <label style="color: red !important">*</label>
                        <validation-provider #default="{ errors }" rules="required" name="City">
                            <v-select v-model="city" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="['Sprint Plannig','Milestone wise','Ghantt Chart']" placeholder="None" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                    <b-col md="4">
                        <b-form-group>
                            <label>Employee</label>
                            <label style="color: red !important">*</label>
                            <validation-provider #default="{ errors }" rules="required" name="Employee">
                                <v-select v-model="employee" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="['All']" placeholder="None" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                        <b-col md="4">
                            <b-form-group>
                                <label>Project</label>
                                <label style="color: red !important">*</label>
                                <validation-provider #default="{ errors }" rules="required" name="Project">
                                    <v-select v-model="project" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="['All']" placeholder="None" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    <!-- <b-col md="4">
                        <b-form-group>
                            <label>Surname</label>
                            <label style="color: red !important">*</label>
                            <validation-provider #default="{ errors }" rules="required" name="surname Name">
                                <b-form-input v-model="surname" placeholder="Enter Surname" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col> -->
                    <b-col md="4">
                <b-form-group>
                  <label>Start Date</label>
                  <label style="color: red !important">*</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Start Date"
                  >
                    <flat-pickr
                      v-model="startdate"
                      class="form-control"
                      :config="{
                          dateFormat: 'd/m/Y',
                          defaultDate: new Date(),
                      }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
                        <b-col md="4">
                    <b-form-group>
                      <label>End Date</label>
                      <label style="color: red !important">*</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="End Date"
                      >
                        <flat-pickr
                          v-model="enddate"
                          class="form-control"
                          :config="{
                              dateFormat: 'd/m/Y',
                              defaultDate: new Date(),
                          }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                        <b-col md="4">
                            <b-form-group>
                                <label>Status</label>
                                <label style="color: red !important">*</label>
                                <validation-provider #default="{ errors }" rules="required" name="Status">
                                    <v-select v-model="status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="['All','Pending','In Progress','Completed','Rejected']" placeholder="None" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!-- submit button -->
                        <b-col cols="12" class="mt-1">
                            <b-button variant="primary"  type="submit"
                                @click.prevent="submitForm" class="mr-4">
                                Submit
                            </b-button>
                            <b-button variant="primary">Back </b-button>
                        </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
// import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import 'flatpickr/dist/flatpickr.css';
import {
    BFormInput,
    BFormFile,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCard
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/component/axios';;

export default {
    components: {
        BMedia,
        BMediaAside,
        BCard,
        BMediaBody,
        BLink,
        BImg,
        flatPickr,
        Datepicker,
        BFormFile,
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        vSelect,
        BFormDatepicker,
        BFormTextarea,
    },
    data() {
        return {
            surname: '',
            employee: '',
            city: '',
            project:'',
            startdate:'',
            enddate: '',
            status: '',
        }
    },
    mounted() {
      
    },
    methods: {
        
    },
};
</script>